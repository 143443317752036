<template>
    <div>
        <div class="card ">
            <div class="card-header d-flex justify-content-between">
                <h5 class="text-uppercase font-weight-bold m-0 ">shipping by zone </h5>

                <button class="btn p-0 btn-link ml-auto "
                    @click=" modalZone = !modalZone ;  fnResetZone(); ">
                    <i class=" fa fa-plus-circle fa-lg animated fadeIn "></i>
                </button>
            </div>

            <div class="card-body p-0 p-md-2 ">

                <table class=" table  d-lg-none  " v-show=" shippingZones.length > 0 ">
                    <thead class="">
                        <tr>
                            <th>Zone</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for=" (zone,index) in  shippingZones  " :key="index" 
                            :class="  { 'bg-light ' : ( zone.id == shippingZone.id  )  }  ">

                            <td class="text-uppercase">

                                <div class="row  align-items-center">
                                    <div class="col">
                                        <small> <span v-text=" 'Name:' ">
                                            </span> <strong v-text="  zone.name "></strong>
                                        </small>
                                    </div>
                                    <div class="col-auto text-capitalize ">

                                        <b-dropdown  variant="outline-primary" size="sm" class=" " no-caret right>
                                            <template v-slot:button-content >
                                                <i class=" fa fa-cog fa-lg "></i>
                                            </template>
                                            <b-dropdown-item @click="fnUpdateShippingZone( index )" variant="info" > <i class="fa fa-pencil-alt "></i>  Edit     </b-dropdown-item>
                                            <b-dropdown-item @click="fnApiDeleteZone(  index )" variant="danger" ><i class="fa fa-trash"></i>  Delete    </b-dropdown-item> 
                                        </b-dropdown>

                                    </div>
                                    
                                </div>
                                <br>
                                <div class="row">
                                    <div class="col-12 col-md-6">
                                        <small>
                                            <span v-text="  'Shipping provider:'  ">
                                            </span>
                                            <strong v-text=" zone.type " :class=" (zone.type == 'own' )? 'text-danger': 'text-primary'   "  ></strong>
                                        </small>
                                    </div>
                                    <div class="col-12 col-md-6">
                                        <small v-if=" zone.type == 'ecart'  " class=" text-uppercase ">
                                            <span v-text=" 'Discount:' ">
                                            </span>
                                            <strong
                                                :class=" zone.discount == 100 ? 'text-danger' : ( zone.discount == 0 ?  'text-warning': 'text-success') "
                                                v-text=" ( zone.discount == 100 ?  'Free shipping' : ( zone.discount == 0 ?  'None'  :  '%' + zone.discount ) ) ">
                                            </strong>
                                        </small>
                                        <div v-else>
                                            <div class="row">
                                                <div class="col-6 col-md-6" v-for=" (rate,index) in  zone.rates "
                                                    :key=" index  ">
                                                    <small>
                                                        <span
                                                            v-text="  ( ( index == 0 ) ?  'Express' : ( ( index == 1 ) ? 'Standard' : ''   ) ) + ':' ">
                                                        </span>
                                                        <strong
                                                            :class="  ( rate.active ) ? 'text-success' : 'text-danger'  "
                                                            v-text=" ( rate.active ) ? 'Active' : 'Inactive' "></strong>
                                                    </small>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>

                            </td>
                        </tr>
                    </tbody>
                </table>

                <div class="d-none d-lg-block">
                    <table class=" table table-sm text-uppercase text-center  " v-show=" shippingZones.length > 0 ">
                        <thead class="">
                            <tr>
                                <th class=" text-left w-25" >Zone name</th>
                                <th >shipping provider</th>
                                <th class=" text-left" >shipping details </th>
                                <th>actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for=" (zone,index) in  shippingZones  " :key="index" 
                                :class="  { 'bg-light ' : ( zone.id == shippingZone.id  )  }  ">
                                <td class=" text-left " >
                                    <strong v-text="  zone.name "></strong>
                                </td>
                                <td>
                                    <small>
                                        <strong class=" p-2 rounded " v-text=" zone.type " :class="  {  'bg-primary':zone.type == 'ecart',  'bg-danger':zone.type == 'own' }  "></strong>
                                    </small>
                                </td>
                                <td class=" text-left  " >
                                    <p v-if=" zone.type == 'ecart'  " >
                                        <span v-text=" 'Discount:' ">
                                        </span>
                                        <strong
                                            :class=" zone.discount == 100 ? 'text-danger' : ( zone.discount == 0 ?  'text-warning': 'text-success') "
                                            v-text=" ( zone.discount == 100 ?  'Free shipping' : ( zone.discount == 0 ?  'None'  :  '%' + zone.discount ) ) ">
                                        </strong>
                                    </p>
                                    <div v-else>
                                        <div class="row">
                                            <div class="col-6 col-md-6" v-for=" (rate,index) in  zone.rates "
                                                :key=" index  ">
                                                    <p class=" text-truncate "> 
                                                    <span
                                                        v-text="  ( ( index == 0 ) ?  'Express' : ( ( index == 1 ) ? 'Standard' : ''   ) ) + ':' ">
                                                    </span>
                                                    <strong :class="  ( rate.active ) ? 'text-success' : 'text-danger'  "
                                                        v-text=" ( rate.active ) ? 'Active' : 'Inactive' "></strong>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </td>

                                <td class="text-capitalize">
                                    <b-dropdown  variant="outline-primary " size="sm" class=" " no-caret right>
                                        <template v-slot:button-content >
                                            <i class=" fa fa-cog fa-lg "></i>
                                        </template>
                                        <b-dropdown-item @click="fnUpdateShippingZone( index )"  variant="info" >  <i class="fa fa-pencil-alt "></i>  Edit </b-dropdown-item>
                                        <b-dropdown-item @click="fnApiDeleteZone(  index )"    variant="danger" > <i class="fa fa-trash"></i>  Delete </b-dropdown-item> 
                                    </b-dropdown>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>


                <no-data v-show=" shippingZones.length == 0 " :isLoading="isLoading" @btn-fn=" modalZone = !modalZone ;  fnResetZone(); "  ></no-data>

            </div>
        </div>
        <b-modal :title=" (shippingZone.id != null) ? 'Update shipping zone' :'Add shipping zone' " v-model="modalZone"
             scrollable content-class="card card-ecart form-rounded-inputs"  header-class="card-header p-3 " cancel-variant="danger" size="md"
             @hidden="fnResetZone()" hide-header-close>

             <!-- ADD ZONE -->
             <div>
                 <h6 class="text-uppercase font-weight-bold text-primary m-0 " v-text=" 'Zone details ' "></h6>

                 

                 <v-observer tag='form' ref="formZone" class=" animated fadeIn " @submit.prevent="fnApiAddZone()">
                     <div class="row">
                         <div class="col-12" :class=" shippingZone.type == 'own' ? 'col-md-6 ' : 'col-md-4'">
                             <div class="form-group">
                                 <label for=""> Zone name </label>

                                 <v-validation rules="required|min:3" v-slot="{ errors }" :name=" 'Zone' ">
                                     <input type="text" :disabled="shippingZone.id != null " @focus=" showAlert = false"
                                         v-model="  shippingZone.name " class="form-control"
                                         :class=" { 'is-invalid':errors.length > 0 } ">

                                     <div class="invalid-feedback" v-if="errors.length > 0">
                                         {{ errors[0] }}
                                     </div>
                                 </v-validation>

                             </div>
                         </div>
                         <div class="col-12" :class=" shippingZone.type == 'own' ? 'col-md-6 ' : 'col-md-4'">
                             <div class="form-group">
                                 <label for=""> Shipping provider <i class=" fa fa-circle text-success "></i> </label>
                                 <v-validation rules="required" v-slot="{ errors }" :name=" 'Type' ">

                                     <select class="form-control" :disabled="shippingZone.id != null "
                                         :class=" { 'is-invalid':errors.length > 0 } " v-model="shippingZone.type ">
                                         <option value="ecart">Ecart
                                         </option>
                                         <option value="own">Own</option>
                                     </select>

                                     <div class="invalid-feedback" v-if="errors.length > 0">
                                         {{ errors[0] }}
                                     </div>
                                 </v-validation>

                             </div>
                         </div>
                         <div class="col-12 col-md-4 " v-if="shippingZone.type == 'ecart' ">
                             <div class="form-group">
                                 <label for=""> Discount <i class=" fa fa-circle text-info "></i> </label>
                                 <v-validation rules="required|min_value:0|max_value:100" v-slot="{ errors }"
                                     :name=" 'Discount' ">
                                     <input type="number" min="0" max="100" v-model="shippingZone.discount "
                                         :class=" { 'is-invalid':errors.length > 0 } " class="form-control ">
                                     <div class="invalid-feedback" v-if="errors.length > 0">
                                         {{ errors[0] }}
                                     </div>
                                 </v-validation>

                             </div>
                         </div>

                         <input type="submit" hidden>

                     </div>
                 </v-observer>
                 <b-alert variant="danger" fade :show="showAlert" >
                    <i class=" fa fa-warning "></i> Zone name is already exist.
                </b-alert>
                 <ul class="my-2" >
                     <li>
                         <small> <i class="  fa fa-circle text-success  "></i> Shipping provider : It is who is responsible for shipping  </small>
                     </li>
                     <li v-show="shippingZone.type == 'ecart'">
                         <small> <i class="  fa fa-circle text-danger  "></i> Ecart : Is our platform which helps
                             companies to
                             integrate with our shipping platform </small>
                     </li>
                     <li v-show="shippingZone.type == 'ecart'">
                         <small> <i class="  fa fa-circle text-info  "></i> Shipping discount: If the discount is 40% and the
                             shipping price is $100 , the seller pay $40 and
                             the buyer $60 </small>
                     </li>
                 </ul>
                 
             </div>
             <!-- UPDATE RATES -->
             <div class="row animated fadeIn " v-show=" shippingZone.type == 'own' && shippingZone.id != null  ">

                 <div class="col-12 ">
                     <hr class=" my-4 ">
                     <h6 class="text-uppercase font-weight-bold text-primary " v-text=" 'shipping prices ' "></h6>
                 </div>

                 <div v-for=" (rate, index ) in shippingZone.rates " class="col-12 " :key="index">

                     <v-observer ref="formRateZone" class=" "
                         @submit.prevent="fnApiAddZone( )" tag="form"
                         v-show="  !shippingZone.rates[0].is_free || index == 0 || !shippingZone.rates[0].active " >

                         <div class="row justify-content-center">
                            <div class="col-12 ">
                                <div class="row">

                                    <div class="col-9">
                                        <div class="row justify-content-between "> 
                                            <div class="col-9 ">
                                                <strong v-text=" rate.label   ">
                                                </strong>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-auto ml-auto text-right ">

                                        <div class="media">
                                            <label class="col-form-label mr-1" :for=" 'zoneRateActive'+ index " > Active </label>
                                            <div class="media-body text-right switch-sm switch-outline">
                                                <label class="switch">
                                                    <input type="checkbox" :id=" 'zoneRateActive'+ index " v-model=" rate.active "><span class="switch-state bg-info"></span>
                                                </label>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="row animated fadeIn" v-show=" rate.active ">
                            <div class="col-8 animated fadeIn ">
                                <p>
                                    <small v-text=" '*' + ( (index == 0 ) ? 'The package arrives 1 to 2 days' : ( (index == 1 ) ? 'The package arrives 3 to 5 days' : ' ' ) )   " >
                                    </small>
                                </p>
                            </div>
                            <div class="col-auto ml-auto text-right">
                                <div class="media">
                                    <label class="col-form-label mr-1" :for=" 'zoneRateFree'+ index " > It`s free </label>
                                        <div class="media-body text-right switch-sm switch-outline">
                                        <label class="switch">
                                            <input type="checkbox" :id=" 'zoneRateFree'+ index " v-model=" rate.is_free " ><span class="switch-state bg-info "></span>
                                        </label>
                                        </div>
                                </div>
                            </div>

                            <div class="col-6 col-sm-6 animated fadeIn" v-show="  !rate.is_free " v-if=" !rate.is_free ">
                                <div class="form-group">
                                    <label for="" v-text=" ' First item ' ">
                                    </label>
                                    <v-validation rules="required|min_value:1" v-slot="{ errors }" :name=" 'Cost' ">
                                        <input type="text" v-model="rate.cost"
                                            :class=" { 'is-invalid':errors.length > 0 } " class="form-control ">
                                        <div class="invalid-feedback" v-if="errors.length > 0">
                                            {{ errors[0] }}
                                        </div>
                                    </v-validation>
                                </div>
                            </div>

                            <div class="col-6 col-sm-6 animated fadeIn" v-show="  !rate.is_free " v-if=" !rate.is_free " >
                                <div class=" form-group">
                                    <label for="" v-text="  'Extra item' ">
                                    </label>
                                    <v-validation rules="required|min_value:1" v-slot="{ errors }" :name=" 'Extra' ">
                                        <input type="text" v-model="rate.extra "
                                            :class=" { 'is-invalid':errors.length > 0 } " class="form-control ">
                                        <div class="invalid-feedback" v-if="errors.length > 0">
                                            {{ errors[0] }}
                                        </div>
                                    </v-validation>
                                </div>

                            </div>
                        </div>

                     </v-observer>

                 </div>
             </div>

             <!-- ADD COUNTRY TO ZONE -->
             <div class="animate fadeIn " v-show=" shippingZone.id != null ">
                 

                 <div class="row"> 
                     <div class="col-12">
                         <hr class=" my-4 ">
                         <h6 class="text-uppercase font-weight-bold text-primary " v-text=" 'shipping countries ' "></h6>
                     </div>

                     <div class="col-12">
                         <v-observer ref="formCountryZone" tag="form" class=" animated fadeIn "
                             @submit.prevent="fnApiAddCountryToZone( )"
                             v-show=" availableCountriesZone && availableCountriesZone.length > 0 ">

                             <div class="row">
                                 <div class="col-8 col-lg-9">
                                     <div class="form-group">
                                         <label for=""> Country </label>
                                         <v-validation rules="required" v-slot="{ errors }" :name=" 'Country' ">
                                             <select class="form-control" :class=" { 'is-invalid':errors.length > 0 } "
                                                 v-model="countryZone.code">
                                                 <option :value="country.code"
                                                     v-for="  (country,index) in availableCountriesZone  " :key="index"
                                                     v-text="country.name">
                                                 </option>
                                             </select>
                                             <div class="invalid-feedback" v-if="errors.length > 0">
                                                 {{ errors[0] }}
                                             </div>
                                         </v-validation>
                                     </div>
                                 </div>

                                 <div class="col-4 col-lg-3">
                                     <div class="form-group">
                                         <label for="">
                                             &nbsp;
                                         </label>
                                         <input type="submit" value="Add" class="btn btn-primary form-control ">
                                     </div>
                                 </div>
                             </div>

                             <p class="m-0 "> <small>* All country within a zone, will have the shipping cost
                                     corresponding to the zone </small> </p>

                         </v-observer>

                     </div>

                     <div class="col-12 ">

                         <table class="table table-striped mt-4 animated fadeIn"
                             v-show=" tempZone.countries.length > 0  ">
                             <thead>
                                 <tr>
                                     <th> Country</th>
                                 </tr>
                             </thead>
                             <tbody>
                                 <tr v-for=" (country,index ) in tempZone.countries  " :key="index"
                                     class=" animated fadeIn">
                                     <td>
                                         <div class=" d-flex justify-content-between  text-uppercase ">
                                             <div class="row w-100 ">
                                                 <div class="col-8 col-md-6 ">
                                                     <p>
                                                         <small> <i class="flag-icon mr-2" :class=" 'flag-icon-' + (country.code).toLowerCase()   "></i>
                                                             <span v-text=" 'name:' "> </span> <strong  v-text=" country.name "></strong> </small>
                                                     </p>
                                                 </div>
                                                 <div class="col-4 col-md-4 ">
                                                     <p>
                                                         <small> <span v-text=" 'code:' "> </span> <strong v-text=" country.code "></strong> </small>
                                                     </p>
                                                 </div>
                                             </div>

                                             <div>
                                                 <button class=" btn btn-outline-danger btn-sm"
                                                    @click=" fnApiDeleteCountryFromZone( index )   ">
                                                    <i class="fa fa-trash "></i>
                                                </button>
                                             </div>

                                         </div>
                                     </td> 
                                 </tr> 

                             </tbody>
                         </table>
 
                             <no-data v-show=" tempZone.countries.length == 0  " dataMessage=" Empty list" :showBtn ='false' >
                             </no-data> 

                     </div>
                 </div>



             </div>

             <!--MODAL FOOTER -->
             <template v-slot:modal-footer>
                 <div class="d-flex jusify-content-end ">
                     <b-button variant="none" size="sm" class=" mr-2"
                         @click=" modalZone = !modalZone ; fnResetZone() " v-text=" 'Close' ">
                     </b-button>
                     <!-- <b-button variant="info" size="sm" class=" " @click=" fnApiAddZone() "
                         v-text=" (shippingZone.id != null) ? 'Update' : 'Save' ">
                     </b-button> -->
                     <b-button variant="info" size="sm" class=" " @click=" fnApiAddZone() " :disabled="sendingDataShippingZone">
                        <span v-text=" (shippingZone.id != null) ? 'Update' : 'Save' " v-if=" !sendingDataShippingZone "  ></span> 
                        <i class="fa fa-spinner fa-spin " v-else ></i>
                    </b-button>

                 </div>
             </template>

         </b-modal>



    </div>
</template>

<script>
    export default {
        props:{
            countriesArray:{
                type: Array,
                default:[],
                required: true,
            },
            shippingZonesArray:{
                type: Array,
                default:[],
                required: true,
            },
            isLoading:{
                type: Boolean,
                default: true ,
                required: true,
            }
        },
        data() {
            return ({

                countriesList: [],
                availableCountries: [],
                availableCountriesZone: [],
                shippingZones: [],
                shippingZone: {
                    type: null,
                    name: null,
                },
                modalZone: false,
                tempZone: {
                    countries: [],
                },
                countryZone: {
                    name: null,
                    code: null,
                },
                sendingDataShippingZone: false,
                showAlert: false,
            })
        },
        watch:{
                countriesArray(){
                    if( this.countriesArray.length > 0  ){
                        this.countriesList = this.$lodash.clone( this.countriesArray ); 

                    }
                },
                shippingZonesArray(){
                    this.shippingZones = this.shippingZonesArray;
                },
                shippingZones(){
                    this.$emit( 'update-zones', 'zones' , this.shippingZones.length )
                }
            },

        methods: {


            async fnApiUpdateZoneRates() {
                let saved = false 
                for (let index in this.shippingZone.rates) {

                    if (await this.$refs['formRateZone'][index].validate()) {

                        let tempRate = this.$lodash.cloneDeep(this.shippingZone.rates[index]);
                        if (tempRate.cost == null) {
                            tempRate.cost = 0
                        }
                        if (tempRate.extra == null) {
                            tempRate.extra = 0
                        }
                        delete tempRate.id;
                        delete tempRate.label;

                        let idx = this.$lodash.findIndex(this.shippingZones, ['id', this.shippingZone.id]);

                        saved = await axios.put( `me/settings/shipping/zones/${this.shippingZone.id}/rates/${this.shippingZone.rates[index].id}`, tempRate).then(response => {

                            this.shippingZones[idx].rates[index] = response.data;
                            let tempRateZone = this.$lodash.cloneDeep(this.shippingZones[idx]);

                            this.shippingZones.splice(idx, 1);
                            this.shippingZones.unshift(tempRateZone);
                            return true;
                        }).catch(error => {

                        });
                        if (index == 0 && this.shippingZone.rates[index].active && this.shippingZone.rates[index].is_free) {
                            break;
                        }

                    } else {
                        return false
                    }

                }
                if (saved) {
                    this.$toasted.global.infoMessage(this.$t('toasted.info.shippingZoneUpdated'));
                }
                return saved;

            }, 
            
            async fnApiAddZone() {

                if (await this.$refs['formZone'].validate()) {
                    this.sendingDataShippingZone = true;
                    let index = this.$lodash.findIndex(this.shippingZones, ['name', this.shippingZone.name]);
                    
                    if (index == -1 || this.shippingZone.id != null) {
                        // Updated
                        if (this.shippingZone.id != null) {
                            let saved = false;
                            if (this.shippingZone.type == 'own') {
                                if (await this.fnApiUpdateZoneRates()) {
                                    this.modalZone = false;
                                    saved = true;
                                }
                            } else {
                                let { type, discount } = this.shippingZone;
                                await axios.put('me/settings/shipping/zones/' + this.shippingZone.id, { type, discount }).then((response) => {
                                    saved = true;
                                    response.data.countries = this.$lodash.cloneDeep( this.tempZone.countries );
                                    this.shippingZones.splice(index, 1);
                                    this.shippingZones.unshift(response.data)
                                    this.fnResetZone();
                                    this.modalZone = false;
                                }).catch(error => {});
                            }
                            if( saved = true ){
                                this.$toasted.global.infoMessage(this.$t('toasted.info.shippingZoneUpdated'));
                            }
                        } else {

                            let saved = false;
                            await axios.post('me/settings/shipping/zones', this.shippingZone).then((response) => {
                                this.shippingZones.unshift(response.data);
                                saved = true;
                            }).catch(error => {});
                            if( saved ){
                                this.$toasted.global.infoMessage(this.$t('toasted.info.shippingZoneCreate'));
                                this.fnUpdateShippingZone(0);
                            }

                        }
                    } else {
                        this.showAlert = true; 
                    }
                    this.sendingDataShippingZone = false;
                }
            },

            fnUpdateShippingZone(index) {
                this.shippingZone = this.$lodash.cloneDeep(this.shippingZones[index]);
                this.modalZone = true;
                this.fnShowZoneCountryModal(index);
            },

            fnResetZone() {
                this.shippingZone = {
                    type: 'ecart',
                    name: null,
                };
            },

            fnApiDeleteZone(index) {
                this.fnResetZone();
                axios.delete('me/settings/shipping/zones/' + this.shippingZones[index].id).then(response => {
                    this.shippingZones.splice(index, 1)
                }).catch(error => {});

            },

            fnShowZoneCountryModal(index) {
                this.tempZone = this.shippingZones[index];

                this.availableCountriesZone = this.$lodash.cloneDeep(this.countriesList);
                this.tempZone.countries = this.fnSortCountries(this.tempZone.countries);

                for (let country of this.tempZone.countries) {
                    this.availableCountriesZone = this.fnDeleteCountry(this.availableCountriesZone, country.code);
                }
                this.tempZone.availableCountries = this.availableCountriesZone;
                if (this.availableCountriesZone.length > 0) {
                    this.countryZone.code = this.availableCountriesZone[0].code
                } 
                this.modalZone = true;
            },

            async fnApiAddCountryToZone() {

                this.countryZone.zone_id = this.tempZone.id;
                this.countryZone.type = this.tempZone.type;

                let index = this.$lodash.findIndex(this.availableCountriesZone, ['code', this.countryZone.code]);
                this.countryZone.name = this.availableCountriesZone[index].name;

                await axios.post('me/settings/shipping/countries', this.countryZone).then(response => {
                    this.tempZone.countries.unshift(response.data);
                    this.availableCountriesZone.splice(index, 1);
                    this.countryZone = {
                        code: this.availableCountriesZone[0].code,
                    }
                    // Sort data
                    this.tempZone.countries = this.fnSortCountries(this.tempZone.countries);


                }).catch(error => {

                });
            },

            async fnApiDeleteCountryFromZone(index) {

                let tempCountry = this.$lodash.cloneDeep(this.tempZone.countries[index]);
                this.tempZone.countries.splice(index, 1);
                let idx = this.$lodash.findIndex(this.shippingZones, ['id', this.tempZone.id]);

                await axios.delete('me/settings/shipping/countries/' + tempCountry.id).then(response => {

                    this.tempZone.availableCountries.push({
                        code: tempCountry.code,
                        name: tempCountry.name
                    })
                }).catch(error => {});

                this.tempZone.availableCountries = this.fnSortCountries(this.tempZone.availableCountries);
                this.availableCountriesZone = this.tempZone.availableCountries;
            },

        },
        mounted() {
        }
    }
</script>

<style>

</style>